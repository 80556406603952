import React, { useState, useEffect, useCallback } from 'react';
import Web3 from 'web3';
import axios from 'axios';
import './App.css';

// Import ABI and contract address
import TokenABI from './TokenABI.json';
import config from './config.json';
const { TOKEN_CONTRACT_ADDRESS } = config;

function App() {
  const [account, setAccount] = useState('');
  const [tokenPrice, setTokenPrice] = useState(null);
  const [stage, setStage] = useState('NotStarted');
  const [web3, setWeb3] = useState(null);
  const [contract, setContract] = useState(null);
  const [balance, setBalance] = useState(0);
  const [purchaseAmount, setPurchaseAmount] = useState('');
  const [purchaseToken, setPurchaseToken] = useState('ETH');
  const [estimatedBYELLOW, setEstimatedBYELLOW] = useState('0');
  const [devModeStage, setDevModeStage] = useState(null);
  const [devModeTokenPrice, setDevModeTokenPrice] = useState(null);
  const [ethPrice, setEthPrice] = useState(null);

  // Move this calculation to the top of the component function
  const displayStage = devModeStage || stage;
  const displayTokenPrice = devModeTokenPrice || tokenPrice;

  const initWeb3 = async () => {
    if (window.ethereum) {
      const web3Instance = new Web3(window.ethereum);
      setWeb3(web3Instance);
      return web3Instance;
    } else if (window.web3) {
      const web3Instance = new Web3(window.web3.currentProvider);
      setWeb3(web3Instance);
      return web3Instance;
    } else {
      window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!');
      return null;
    }
  };

  const connectWallet = async () => {
    try {
      let web3Instance = web3;
      if (!web3Instance) {
        web3Instance = await initWeb3();
        if (!web3Instance) return; // Exit if Web3 initialization failed
      }
      
      const accounts = await web3Instance.eth.requestAccounts();
      setAccount(accounts[0]);
      await loadBlockchainData(web3Instance);
    } catch (error) {
      console.error("Failed to connect wallet:", error);
    }
  };

  const loadBlockchainData = useCallback(async (web3Instance) => {
    try {
      // Remove the unused networkId line
      const accounts = await web3Instance.eth.getAccounts();
      if (accounts.length > 0) {
        setAccount(accounts[0]);
      }

      const tokenContract = new web3Instance.eth.Contract(TokenABI, TOKEN_CONTRACT_ADDRESS);
      setContract(tokenContract);

      // Load contract data
      const currentStage = await tokenContract.methods.currentStage().call();
      setStage(getStageString(currentStage));

      const stagePrice = await tokenContract.methods.stagePriceUSDT(currentStage).call();
      setTokenPrice(web3Instance.utils.fromWei(stagePrice, 'mwei')); // Convert from USDT's 6 decimals

      if (accounts[0]) {
        const bal = await tokenContract.methods.balanceOf(accounts[0]).call();
        setBalance(web3Instance.utils.fromWei(bal, 'ether'));
      }
    } catch (error) {
      console.error("An error occurred while loading blockchain data:", error);
    }
  }, []);

  useEffect(() => {
    const initWeb3 = async () => {
      if (window.ethereum) {
        const web3Instance = new Web3(window.ethereum);
        setWeb3(web3Instance);
        await loadBlockchainData(web3Instance);
      } else {
        console.error('Ethereum wallet not detected');
      }
    };

    initWeb3();
  }, [loadBlockchainData]);

  // Add a new useEffect to set devModeStage
  useEffect(() => {
    if (stage !== 'NotStarted') {
      setDevModeStage(stage);
    }
  }, [stage]);

  const purchaseTokens = async () => {
    if (!contract || !account) return;

    try {
      if (purchaseToken === 'ETH') {
        const ethAmount = web3.utils.toWei(purchaseAmount, 'ether');
        await contract.methods.purchaseTokens(web3.utils.padLeft(0, 40)).send({
          from: account,
          value: ethAmount
        });
      } else if (purchaseToken === 'USDT') {
        await contract.methods.purchaseTokens(TOKEN_CONTRACT_ADDRESS).send({
          from: account,
          value: 0
        });
      }
      // Refresh balance after purchase
      const newBalance = await contract.methods.balanceOf(account).call();
      setBalance(web3.utils.fromWei(newBalance, 'ether'));
    } catch (error) {
      console.error('Error purchasing tokens:', error);
    }
  };

  const getStageString = (stageNumber) => {
    const stages = ['NotStarted', 'Stage1', 'Stage2', 'Stage3', 'Ended'];
    return stages[stageNumber] || 'Unknown';
  };

  useEffect(() => {
    const fetchEthPrice = async () => {
      try {
        const response = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd');
        setEthPrice(response.data.ethereum.usd);
      } catch (error) {
        console.error('Error fetching ETH price:', error);
        setEthPrice(2000); // 使用默认值作为后备
      }
    };

    fetchEthPrice();
    // 每60秒更新一次价格
    const interval = setInterval(fetchEthPrice, 60000);

    return () => clearInterval(interval);
  }, []);

  const calculateEstimatedBYELLOW = useCallback(() => {
    if (!purchaseAmount || !displayTokenPrice) return '0';
    
    let amountInUSDT;
    if (purchaseToken === 'ETH') {
      amountInUSDT = parseFloat(purchaseAmount) * (ethPrice || 2000);
    } else {
      amountInUSDT = parseFloat(purchaseAmount);
    }
    
    const estimatedAmount = amountInUSDT / parseFloat(displayTokenPrice);
    return estimatedAmount.toFixed(2);
  }, [purchaseAmount, purchaseToken, displayTokenPrice, ethPrice]);

  useEffect(() => {
    setEstimatedBYELLOW(calculateEstimatedBYELLOW());
  }, [purchaseAmount, purchaseToken, calculateEstimatedBYELLOW]);

  const updateStageAndPrice = (newStage) => {
    setDevModeStage(newStage);
    let newPrice;
    switch (newStage) {
      case 'Stage1':
        newPrice = '0.001';
        break;
      case 'Stage2':
        newPrice = '0.01';
        break;
      case 'Stage3':
        newPrice = '0.05';
        break;
      case 'Ended':
        newPrice = '0.1';
        break;
      default:
        newPrice = null;
    }
    setDevModeTokenPrice(newPrice);
  };

  const toggleDevModeStage = () => {
    const stages = ['NotStarted', 'Stage1', 'Stage2', 'Stage3', 'Ended'];
    const currentIndex = stages.indexOf(devModeStage || stage);
    const nextIndex = (currentIndex + 1) % stages.length;
    updateStageAndPrice(stages[nextIndex]);
  };

  return (
    <div className="App">
      <header className="App-header">
        <div className="header-content">
          <h1>Book Yellow ICO</h1>
          <button onClick={connectWallet} className="connect-wallet-btn">
            {account ? `Connected: ${account.slice(0, 6)}...${account.slice(-4)}` : 'Connect Wallet'}
          </button>
        </div>
      </header>
      <main className="card-layout">
        <section className="card project-info">
          <h2>项目背景</h2>
          <p className="project-description">
          我们开发这款DApp的初衷是为了突破某些地区对言论自由的限制，为用户提供更加安全匿名的交流环境。该BBS系统基于区块链技术，所有内容不可篡改且免受审查，同时采用匿名机制，用户无需提供个人身份信息，确保言论自由的同时保护隐私。所有发布的内容将永久记录在链上，保证信息的持久性和透明性。
          </p>
        </section>

        <section className="card token-info">
          <h2>代币信息</h2>
          <p className="project-description">
          Book Yellow代币（BYELLOW）总供应量为100亿枚。该代币主要用于DApp内的经济活动管理，如发帖、点赞、回复等互动操作，同时还可用于支付信息上链时的gas费用。为了鼓励内容创作者，系统还设有代币奖励机制。此外，针对物品交易信息，BYELLOW代币可作为中介担保工具，确保交易过程的安全与可靠。DApp正式上线后，BYELLOW代币也将同步在去中心化交易所（DEX）上线，供用户自由交易。
          </p>
        </section>

        <section className="card ico-progress">
          <h2>ICO 进度</h2>
          <p>当前ICO阶段: {displayStage}</p>
          <p>当前币价格: {displayTokenPrice} USDT per BYELLOW</p>
          <div className="stage-progress">
            <div className="stage-circles">
              <div className="circle-container">
                <div className={`circle ${stage === 'Stage1' ? 'active' : ''}`} style={{backgroundColor: '#E6E6FA'}}>
                  <span>一</span>
                  <span className="price">0.001U</span>
                </div>
                <div className="connector"></div>
              </div>
              <div className="circle-container">
                <div className={`circle ${stage === 'Stage2' ? 'active' : ''}`} style={{backgroundColor: '#FFB6C1'}}>
                  <span>二</span>
                  <span className="price">0.01U</span>
                </div>
                <div className="connector"></div>
              </div>
              <div className="circle-container">
                <div className={`circle ${stage === 'Stage3' ? 'active' : ''}`} style={{backgroundColor: '#FFFACD'}}>
                  <span>三</span>
                  <span className="price">0.05U</span>
                </div>
                <div className="connector"></div>
              </div>
              <div className="circle-container">
                <div className={`circle ${stage === 'Ended' ? 'active' : ''}`} style={{backgroundColor: '#90EE90'}}>
                  <span>四</span>
                  <span className="price">0.1U</span>
                </div>
              </div>
            </div>
            <div className="stage-descriptions">
              <div className="stage-description">
                <h3>第一阶段</h3>
                <p>代币预售价格：0.001 USDT</p>
                <p>任务：开发 DApp 框架，奠定项目基础</p>
              </div>
              <div className="stage-description">
                <h3>第二阶段</h3>
                <p>代币预售价格：0.01 USDT</p>
                <p>任务：DApp 在测试网上运行，进行功能测试和优化</p>
              </div>
              <div className="stage-description">
                <h3>第三阶段</h3>
                <p>代币预售价格：0.05 USDT</p>
                <p>任务：DApp 上主网试运行，确保稳定性和安全性</p>
              </div>
              <div className="stage-description">
                <h3>第四阶段</h3>
                <p>代币预售价格：0.1 USDT</p>
                <p>任务：DApp 定型，全面上线运营</p>
              </div>
            </div>
          </div>
          {account && <p>你的BYELLOW余额: {balance}</p>}
          {/* Add a button to toggle dev mode stage */}
          <button onClick={toggleDevModeStage} className="dev-mode-toggle">
            Toggle Dev Mode Stage
          </button>
        </section>

        <section className="card token-purchase">
          <h2>购买代币</h2>
          <div className="purchase-form">
            <div className="input-group">
              <input 
                type="number" 
                value={purchaseAmount} 
                onChange={(e) => setPurchaseAmount(e.target.value)}
                placeholder="输入金额"
              />
              <select value={purchaseToken} onChange={(e) => setPurchaseToken(e.target.value)}>
                <option value="ETH">ETH</option>
                <option value="USDT">USDT</option>
              </select>
            </div>
            {purchaseToken === 'ETH' && ethPrice && (
              <p className="eth-price">当前ETH价格: ${ethPrice} USDT</p>
            )}
            <p className="estimated-amount">预计可购买 BYELLOW: {estimatedBYELLOW}</p>
            <button 
              onClick={purchaseTokens} 
              disabled={!account || !purchaseAmount || parseFloat(estimatedBYELLOW) < 1}
            >
              购买代币
            </button>
            {parseFloat(estimatedBYELLOW) < 1 && purchaseAmount && (
              <p className="error-message">购买数量必须至少为1 BYELLOW</p>
            )}
          </div>
        </section>
      </main>
      <footer className="App-footer">
        <div className="footer-content">
          <h2>Little Yellow Book</h2>
          <div className="contact-section">
            <p>请联系我们:</p>
            <div className="contact-buttons">
              <button onClick={() => window.open('https://t.me/your_telegram_group', '_blank')}>Telegram</button>
              <button onClick={() => window.location.href = 'mailto:2st_web3@proton.me'}>Email</button>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;